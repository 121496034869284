import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-730ec826"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"margin-bottom":"10%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_menu, {
      "default-active": _ctx.activeIndex,
      class: "el-menu-demo",
      mode: "horizontal",
      onSelect: _ctx.handleSelect,
      style: {"border":"none"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_sub_menu, { index: "1" }, {
          title: _withCtx(() => [
            _createTextVNode("万非系列")
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_menu_item, { index: "1-1" }, {
              default: _withCtx(() => [
                _createTextVNode("沐浴乳")
              ]),
              _: 1
            }),
            _createVNode(_component_el_menu_item, { index: "1-2" }, {
              default: _withCtx(() => [
                _createTextVNode("面膜")
              ]),
              _: 1
            }),
            _createVNode(_component_el_menu_item, { index: "1-3" }, {
              default: _withCtx(() => [
                _createTextVNode("芦荟水")
              ]),
              _: 1
            }),
            _createVNode(_component_el_menu_item, { index: "1-4" }, {
              default: _withCtx(() => [
                _createTextVNode("芦荟凝胶")
              ]),
              _: 1
            }),
            _createVNode(_component_el_menu_item, { index: "1-5" }, {
              default: _withCtx(() => [
                _createTextVNode("洁面泡")
              ]),
              _: 1
            }),
            _createVNode(_component_el_menu_item, { index: "1-6" }, {
              default: _withCtx(() => [
                _createTextVNode("冻干粉")
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_sub_menu, { index: "2" }, {
          title: _withCtx(() => [
            _createTextVNode("蕙芷系列")
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_menu_item, { index: "2-1" }, {
              default: _withCtx(() => [
                _createTextVNode("大麦粉")
              ]),
              _: 1
            }),
            _createVNode(_component_el_menu_item, { index: "2-2" }, {
              default: _withCtx(() => [
                _createTextVNode("梨膏")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["default-active", "onSelect"]),
    _createVNode(_component_el_row, { gutter: 20 }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.num, (item, index) => {
          return (_openBlock(), _createBlock(_component_el_col, {
            span: 8,
            key: index
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_image, {
                src: require('@/assets/products/' + item),
                "preview-src-list": [require('@/assets/products/' + item)]
              }, null, 8, ["src", "preview-src-list"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ]))
}