
import { Options, Vue } from "vue-class-component";
@Options({
  components: {},
  data() {
    return {
      num: [],
      activeIndex: "1-1",
      currentIndex: "1-1",
      arr_img: [],
      showTip: false,
    };
  },
  created() {
    const path = require("path");
    const images_list = require.context(
      "@/assets/products/",
      true,
      /\.(png|jpe?g|gif)$/
    ); // 修改路径为你自己的图片文件夹路径
    // const arr_img: Array<string> = [];
    images_list.keys().forEach((key) => {
      const name = key.replace(/\.\//g, "");
      this.arr_img.push(name);
    });
    // this.num = this.arr_img;
    this.getCategoryImgs("1-1");
    // console.log(this.num);
  },
  methods: {
    getCategoryImgs(fileName: string) {
      let arr: Array<string> = [];
      this.arr_img.forEach((v: string) => {
        if (v.indexOf(fileName) != -1) {
          arr.push(v);
        }
      });
      this.num = arr;
      // console.log(1,arr)
    },
    handleSelect(key: string, keyPath: string[]) {
      // console.log(key, keyPath);
      this.currentIndex = key;
      this.getCategoryImgs(key);
    },
  },
})
export default class product extends Vue {}
